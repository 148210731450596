import React, { useReducer, useEffect } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/truck_landscape.jpg";
import defaultHeadshot from "images/default_headshot.png";
import Modal from "react-modal";
import ApplicationReducer from "context/ApplicationReducer";
import ApplicationContext from "context/ApplicationContext";
import ContactForm from "components/forms/SimpleContactUs";
import ToroJunkman from "images/toro_junkman.png";
import ToroJunkman2 from "images/toro_junkman_2.png";
import ToroJunkman3 from "images/toro_junkman_3.png";

const SaaSProductLandingPage = () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const [state, dispatch] = useReducer(ApplicationReducer, {
    modalIsOpen: false,
    hideBackgroundScroll: false,
  });

  const afterOpenModal = () => {
    console.log("Modal opened");
  };

  const closeModal = () => {
    dispatch({
      type: "closeModal",
    });
  };

  // to control scrolling while modal is open
  useEffect(() => {
    state.hideBackgroundScroll && (document.body.style.overflow = "hidden");
    !state.hideBackgroundScroll && (document.body.style.overflow = "unset");
  }, [state.hideBackgroundScroll]);

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <AnimationRevealPage disabled="true">
        <Hero roundedHeaderButton={true} />
        <Modal
          isOpen={state.modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={false}
        >
          <ContactForm />
        </Modal>
        {/* <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      /> */}
        <MainFeature
          subheading={<Subheading>About Us</Subheading>}
          imageSrc={heroScreenshotImageSrc}
          imageBorder={true}
          imageDecoratorBlob={true}
        />
        {/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}
        {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      /> */}
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Rates by <HighlightedText>Volume</HighlightedText>
            </>
          }
          plans={[
            {
              name: "SMALL LOAD",
              price: "",
              duration: "",
              mainFeature: (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={ToroJunkman}
                    height="50%"
                    width="50%"
                    alt="Small Load"
                  />
                </div>
              ),
              features: [],
            },
            {
              name: "MEDIUM LOAD",
              price: "",
              duration: "", // Monthly
              mainFeature: (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={ToroJunkman2}
                    height="90%"
                    width="90%"
                    alt="Medium Load"
                  />
                </div>
              ),
              features: [],
              featured: false,
            },
            {
              name: "LARGE LOAD",
              price: "",
              duration: "",
              mainFeature: (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={ToroJunkman3}
                    height="90%"
                    width="90%"
                    alt="Medium Load"
                  />
                </div>
              ),
              features: [],
            },
          ]}
        />
        <Testimonial
          subheading={<Subheading>Testimonials</Subheading>}
          heading={
            <>
              Our <HighlightedText>Toro</HighlightedText> Clients
            </>
          }
          testimonials={[
            {
              stars: 5,
              profileImageSrc: defaultHeadshot,
              heading: "Prompt and Professional",
              quote:
                "As a Realtor, I frequently have situations that require hauling. On a recent listing, I had two opportunities to use Toro Junk Removal. In both cases, they were prompt, professional, and very helpful. Every member of the team was polite, hard working, and customer service oriented. I will definitely use Toro Junk Removal again and highly recommend them.",
              customerName: "Linda Lieberman",
              customerTitle: "Aspire Realty Group, LLC",
            },
            {
              stars: 5,
              profileImageSrc: defaultHeadshot,
              heading: "Best in the East Valley",
              quote:
                "I recently used Toro to help me get rid of a ton of trash and random junk as part of a move-out. [The Toro] team responded immediately and were onsite before I even realized. They quickly took care of everything and left the property in spotless condition. I was surprised at how reasonable their fees were, too! I highly recommend Toro for anyone who cares about results, value, and excellent customer service. They’re the best hauling company in the East valley!",
              customerName: "Kimberli Davis",
              customerTitle: "",
            },
            {
              stars: 5,
              profileImageSrc: defaultHeadshot,
              heading: "Reasonable Rates & Good Attitudes",
              quote:
                "Every once in a while, my Sellers have a hard time getting everything out and decide that whatever’s left is stuff they don’t want anymore. Toro Junk Removal to the rescue! Reasonable rates, strong arms and good attitudes. I highly recommend them for all your hauling needs. When you don’t have the time, energy, or equipment to get rid of your stuff, give them your business.",
              customerName: "Julia Fordtner",
              customerTitle: "Aspire Realty Group, LLC",
            },
          ]}
        />
        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }
          // faqs={[
          //   {
          //     question: "Are all the templates easily customizable ?",
          //     answer:
          //       "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          //   {
          //     question:
          //       "How long do you usually support an standalone template for ?",
          //     answer:
          //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          //   {
          //     question: "What kind of payment methods do you accept ?",
          //     answer:
          //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          //   {
          //     question:
          //       "Is there a subscribption service to get the latest templates ?",
          //     answer:
          //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          //   {
          //     question: "Are the templates compatible with the React ?",
          //     answer:
          //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          //   {
          //     question: "Do you really support Internet Explorer 11 ?",
          //     answer:
          //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          //   },
          // ]}
        />
        <Footer />
      </AnimationRevealPage>
    </ApplicationContext.Provider>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)", // background BEHIND the modal
  },
  // content: {
  //   position: "absolute",
  //   top: "40px",
  //   left: "40px",
  //   right: "40px",
  //   bottom: "40px",
  //   border: "1px solid #ccc",
  //   background: "#fff",
  //   overflow: "auto",
  //   WebkitOverflowScrolling: "touch",
  //   borderRadius: "4px",
  //   outline: "none",
  //   padding: "20px",
  // },
  content: {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    minHeight: "10rem",
    left: "50%",
    padding: "2rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "20rem",
    width: "90%",
    height: "100%",
    flex: 1,
    maxWidth: "60rem",
    backgroundColor: "rgba(0,0,0,0)",
  },
};

export default SaaSProductLandingPage;
