const ApplicationReducer = (state, action) => {
  const { key, type, value } = action;

  switch (type) {
    case "openModal": {
      const newState = { ...state };
      // newState.modalIsOpen = !newState.modalIsOpen;
      newState.modalIsOpen = true;
      newState.hideBackgroundScroll = true;
      return newState;
    }
    case "closeModal": {
      const newState = { ...state };
      // newState.modalIsOpen = !newState.modalIsOpen;
      newState.modalIsOpen = false;
      newState.hideBackgroundScroll = false;
      return newState;
    }

    default: {
      return state;
    }
  }
};

export default ApplicationReducer;
