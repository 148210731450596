import React, { useContext, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Close } from "@material-ui/icons";
import ApplicationContext from "context/ApplicationContext";
import emailjs from "emailjs-com";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-200`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw(
  PrimaryButtonBase
)`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const ContactForm = () => {
  const { dispatch } = useContext(ApplicationContext);
  const [displayForm, setDisplayForm] = useState(true);
  // google spreadsheet auth
  const { GoogleSpreadsheet } = require("google-spreadsheet");
  const creds = require("../../config/toro-junk-removal-c4da711c7641.json"); // the file saved above
  const doc = new GoogleSpreadsheet(
    "1_wAj9n6vbFxIUXCyV4upSax9kihEz80mSwsy66q5OOg"
  );
  (async function () {
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    // console.log(doc.title);
  })();

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisplayForm(false);
    const data = new FormData(event.target);
    // console.log(data.get("email")); // reference by form input's `name` tag
    (async function () {
      const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
      const newEntry = await sheet.addRow({
        First: data.get("fname"),
        Last: data.get("lname"),
        "Cell Phone": data.get("phone"),
        Email: data.get("email"),
        Address: data.get("address"),
        Message: data.get("message"),
      });
    })();
    sendEmail(event);
  };

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_wgzehhq",
        "template_095lyrj",
        e.target,
        "user_LJiXoWeDUwiav12D6QK53"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <button
                style={{
                  outline: "none",
                  alignSelf: "flex-end",
                  justifySelf: "flex-start",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "closeModal",
                  });
                }}
              >
                <Close fontSize="large" />
              </button>
              {displayForm ? (
                <h2>Get a Rate</h2>
              ) : (
                <h3>Thank you! We'll get back to you soon.</h3>
              )}
            </div>
            {displayForm && (
              <form onSubmit={handleSubmit}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">First Name</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="fname"
                        // placeholder="e.g. John"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="name-input">Last Name</Label>
                      <Input
                        id="name-input"
                        type="text"
                        name="lname"
                        // placeholder="e.g. Doe"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Cell Phone</Label>
                      <Input
                        id="cell-input"
                        type="phone"
                        name="phone"
                        // placeholder="E.g. john@mail.com"
                      />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="email-input">Email Address</Label>
                      <Input
                        id="email-input"
                        type="email"
                        name="email"
                        // placeholder="john@mail.com"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Street Address</Label>
                      <Input
                        id="address-input"
                        type="address"
                        name="address"
                        // placeholder="E.g. john@mail.com"
                      />
                    </InputContainer>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="name-input">Your Message</Label>
                      <TextArea
                        id="message-input"
                        name="message"
                        placeholder="Details about the job"
                      />
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <SubmitButton type="submit" value="Submit">
                  Submit
                </SubmitButton>
              </form>
            )}
          </div>
          {/* <SvgDotPattern1 /> */}
        </FormContainer>
      </Content>
    </Container>
  );
};

export default ContactForm;
